import React, { useEffect } from "react";
import './index.css';

function Giraff() {
  return (
  <div className="giraf-container">
  <svg id="Laag_1" data-name="Laag 1" xmlns="http://www.w3.org/2000/svg" overflow="visible" viewBox="0 0 90.33 144.58">
    <g className="giraf__body">
      <path className="g-yellow" d="M72.23,149.15c-.09-1.33-.17-2.65-.25-4-.17-2.41-.35-4.83-.54-7.24-.37-4.71-.82-9.61-1.33-14.57-1-9.72-2.25-19.49-3.69-29-.69-4.55-1.44-9.12-2.23-13.58a7.09,7.09,0,0,0,0-1.95.32.32,0,0,0-.18-.24C63.15,69.8,62.52,68,62.52,68l-3.85-2.13L47.38,70.32l-.76,9.58c-.37,3.23-.79,6.53-1.26,10.06l0-.13c-1,7.55-2.19,15.21-3.33,22.61-.34,2.28-.69,4.55-1,6.82-1.33,8.8-2.84,19.18-3.91,29.45l-.18,1.73H72.3ZM47.38,81l.16.22-.2.15C47.36,81.29,47.37,81.16,47.38,81Z" transform="translate(-6.86 -5.86)"/>
      <path className="cheek" d="M64.51,98.29l-.65.08-.22,0a.48.48,0,0,0-.2-.07,8.7,8.7,0,0,0-2.72.62,6.6,6.6,0,0,0-2.06,1.36,8.11,8.11,0,0,0-2.15,4.16,14.5,14.5,0,0,0,0,5.27,18.56,18.56,0,0,0,1.6,5.14,14.44,14.44,0,0,0,1.26,2.18,10.72,10.72,0,0,0,1.53,1.81,7.5,7.5,0,0,0,1.74,1.23,5.79,5.79,0,0,0,1,.37,4.59,4.59,0,0,0,1,.15h0a4.43,4.43,0,0,0,2-.35,2.56,2.56,0,0,0,.73-.48q-.26-3-.47-6c-.12-1.07-.23-2.14-.32-3.21-.57-4.09-1.1-8.18-1.49-12.29Z" transform="translate(-6.86 -5.86)"/>
      <path className="cheek" d="M49.32,125.24a5.12,5.12,0,0,0-1,.9h0a20.66,20.66,0,0,0-2.19,8.46,7,7,0,0,0,.5,2.76,4.61,4.61,0,0,0,.31.59,3.64,3.64,0,0,0,.43.58,3.88,3.88,0,0,0,1,.82,4.55,4.55,0,0,0,1.39.48,6.34,6.34,0,0,0,.72.08,4.51,4.51,0,0,0,.64,0,3.55,3.55,0,0,0,1.83-.68,4.45,4.45,0,0,0,1.27-1.49,7.35,7.35,0,0,0,.71-2,11.09,11.09,0,0,0,.25-2.33,15.25,15.25,0,0,0-.68-4.66,9.81,9.81,0,0,0-.85-2,4.91,4.91,0,0,0-1.14-1.39,2.68,2.68,0,0,0-1.53-.63h0a2.79,2.79,0,0,0-1.6.48Z" transform="translate(-6.86 -5.86)"/>
      <path className="cheek" d="M67.05,130.89c-3.28.88-6.78,6.14-6.71,9.44a6,6,0,0,0,.49,2.26,2.65,2.65,0,0,0,.28.51,5.4,5.4,0,0,0,.43.52,8.71,8.71,0,0,0,1.11,1,14.28,14.28,0,0,0,3.05,1.88,7.55,7.55,0,0,0,1.55.51,3.19,3.19,0,0,0,.54.07,2.71,2.71,0,0,0,.86-.08,2.38,2.38,0,0,0,1.45-1.46c.06-.12.11-.24.16-.36L69.93,143a1.48,1.48,0,0,1,0-.19q-.87-6-1.54-11.94l-.13,0-.26,0a3.27,3.27,0,0,0-.92.12Z" transform="translate(-6.86 -5.86)"/>
      <g id="spriet-r"></g>
    
    <g className="giraf__head">
    <path className="g-white" d="M47.35,128.1l0-.07,0,.07Z" transform="translate(-6.86 -5.86)"/>
    <path className="g-yellow" d="M58.78,11.32a1.61,1.61,0,0,0-.73,1c-.13,1-.23,2-.36,2.94a2.51,2.51,0,0,1,0,.27c-.13.87-.28,1.74-.42,2.6-.33,2-.64,4.08-.84,6.13a1.78,1.78,0,0,0,.24,1.27,1.67,1.67,0,0,0,.61.57,1.88,1.88,0,0,0,.76.41,1.76,1.76,0,0,0,1.31-.21A1.63,1.63,0,0,0,60,25.3c.12-1,.19-2,.29-2.95s.26-2.18.41-3.27c.28-2.1.6-4.21.73-6.34a1.8,1.8,0,0,0-1.36-1.63,1.57,1.57,0,0,0-.3,0,1.73,1.73,0,0,0-1,.26Z" transform="translate(-6.86 -5.86)"/>
    <path className="g-lines" d="M59.83,6.38a2.8,2.8,0,0,0-2.1,1,3.32,3.32,0,0,0-.61,1.18A4.62,4.62,0,0,0,57,9.77a5.83,5.83,0,0,0,.35,2.06,5.79,5.79,0,0,0,1.24,2.08,3,3,0,0,0,2.09.94,2.84,2.84,0,0,0,2.47-1.39,4.35,4.35,0,0,0,.49-2.3c0-.15,0-.29,0-.43a6.89,6.89,0,0,0-.22-1.27,4.8,4.8,0,0,0-1.15-2.08,3.13,3.13,0,0,0-2.16-1Z" transform="translate(-6.86 -5.86)"/>
    <path className="g-yellow" d="M58.47,6.17a3.19,3.19,0,0,1,1.36-.31h.22a3.84,3.84,0,0,1,1.38.34A3.45,3.45,0,0,1,62.56,7a5.26,5.26,0,0,1,.79,1.08,5.56,5.56,0,0,1,.49,1.22c0,.21.1.44.14.67h0c0,.23.07.46.09.68h0a1.64,1.64,0,0,1,0,.22,1.85,1.85,0,0,1,0,.25A6.18,6.18,0,0,1,64,12.44a3.73,3.73,0,0,1-.47,1.28h0a3.45,3.45,0,0,1-1.24,1.22,3.24,3.24,0,0,1-1.67.42h0a3.46,3.46,0,0,1-2.48-1.1h0a7.71,7.71,0,0,1-.78-1A5.87,5.87,0,0,1,56.81,12a7.44,7.44,0,0,1-.29-1.08,5.13,5.13,0,0,1-.09-1.17V9.69a4.66,4.66,0,0,1,.19-1.31h0a4.12,4.12,0,0,1,.27-.69A3.16,3.16,0,0,1,57.34,7h0a3.21,3.21,0,0,1,1.13-.86Zm1.35.72a2.24,2.24,0,0,0-.91.21,2.33,2.33,0,0,0-.78.59h0a1.86,1.86,0,0,0-.3.45,4.19,4.19,0,0,0-.22.55h0a4.37,4.37,0,0,0-.14,1.05V9.8a3.64,3.64,0,0,0,.07.92,7.81,7.81,0,0,0,.25.95,4.8,4.8,0,0,0,.46,1,6.44,6.44,0,0,0,.68.9h0a2.19,2.19,0,0,0,.76.54,2.3,2.3,0,0,0,2.1-.07,2.41,2.41,0,0,0,.86-.85h0a2.79,2.79,0,0,0,.34-.94,4.87,4.87,0,0,0,.08-1.08c0-.05,0-.11,0-.19s0-.13,0-.19v0c0-.19,0-.39-.08-.59h0c0-.18-.08-.38-.13-.59a4.36,4.36,0,0,0-.39-1,4.31,4.31,0,0,0-.64-.88,2.48,2.48,0,0,0-.8-.58,2.64,2.64,0,0,0-1-.25h-.17Z" transform="translate(-6.86 -5.86)"/>
  <g id="spriet-l">
    <path className="g-yellow" d="M38.18,11.93a1.61,1.61,0,0,0-1,.8A1.75,1.75,0,0,0,37.14,14c.48,1.84,1,3.67,1.55,5.5.44,1.48.87,3,1.39,4.43.1.41.21.83.32,1.24a1.5,1.5,0,0,0,1,1,1.62,1.62,0,0,0,1.33.16,1.64,1.64,0,0,0,1-2.09c-.46-1.42-.93-2.84-1.37-4.26-.13-.47-.25-.93-.39-1.39-.28-1-.59-2-.89-3-.25-.85-.5-1.7-.77-2.55a1.7,1.7,0,0,0-1.55-1.17,1.39,1.39,0,0,0-.57.06Z" transform="translate(-6.86 -5.86)"/>
    <path className="g-lines" d="M38,8.5a6.31,6.31,0,0,0-.73.38,7.79,7.79,0,0,0-.77.65,7.34,7.34,0,0,0-1.06,1.35,4.9,4.9,0,0,0-.42.78,3.89,3.89,0,0,0-.41,1,2.72,2.72,0,0,0-.05.7,3.35,3.35,0,0,0,0,.71,1.89,1.89,0,0,0,.59,1.23,1.81,1.81,0,0,0,1,.47,1.89,1.89,0,0,0,.34.09,3,3,0,0,0,2-.4,3.52,3.52,0,0,0,.5-.36,8.18,8.18,0,0,0,.81-.74,7.87,7.87,0,0,0,1-1.41,3.19,3.19,0,0,0,.43-1.27,3.76,3.76,0,0,0-.14-1.88,2.32,2.32,0,0,0-1.58-1.44l-.43-.06A2.41,2.41,0,0,0,38,8.5Z" transform="translate(-6.86 -5.86)"/>
    <path className="g-yellow" d="M37.38,8.22,37.77,8a3.22,3.22,0,0,1,.67-.2,3.35,3.35,0,0,1,.71,0l.25,0,.25,0h0a2.37,2.37,0,0,1,1.16.61,3.33,3.33,0,0,1,.8,1.14,3.55,3.55,0,0,1,.23,1.11,5.78,5.78,0,0,1-.05,1,4.09,4.09,0,0,1-.5,1.47,9.06,9.06,0,0,1-.49.77,8.54,8.54,0,0,1-.59.73h0c-.13.14-.27.27-.42.41s-.3.26-.44.38l-.26.21-.32.2h0a4.85,4.85,0,0,1-1.07.42,3.15,3.15,0,0,1-1.23,0l-.22-.05h0l-.16-.05a2.47,2.47,0,0,1-.64-.19,2.25,2.25,0,0,1-.61-.41A2,2,0,0,1,34.3,15a3.17,3.17,0,0,1-.22-.8h0a2.73,2.73,0,0,1,0-.4v-.38A2.81,2.81,0,0,1,34,13a3.67,3.67,0,0,1,.07-.42h0a4.62,4.62,0,0,1,.19-.6,3.16,3.16,0,0,1,.26-.52c.06-.14.12-.28.19-.41s.17-.29.26-.43h0c.16-.24.32-.48.51-.73a6,6,0,0,1,.63-.71h0l0,0a6.53,6.53,0,0,1,.8-.66h0c.14-.09.28-.16.41-.23ZM38.2,9l-.35.16a2.91,2.91,0,0,0-.3.17h0a8.47,8.47,0,0,0-.67.57l0,0h0a5.59,5.59,0,0,0-.51.57l-.47.68h0c-.08.12-.14.23-.2.34s-.12.24-.17.36l0,0v0a3.17,3.17,0,0,0-.21.41c0,.14-.1.29-.14.45h0a2,2,0,0,0,0,.26,1.4,1.4,0,0,0,0,.29v.06a1.61,1.61,0,0,0,0,.31,1.43,1.43,0,0,0,0,.29h0a2.13,2.13,0,0,0,.14.54,1,1,0,0,0,.29.39,1.27,1.27,0,0,0,.34.23,1.34,1.34,0,0,0,.39.11h.07l.06,0,.12,0h0l.13,0a2,2,0,0,0,.82,0,3.7,3.7,0,0,0,.82-.33h0l.19-.12.22-.18.4-.34a4.07,4.07,0,0,0,.36-.36,5,5,0,0,0,.5-.62,6.2,6.2,0,0,0,.44-.69,2.45,2.45,0,0,0,.24-.52,2.68,2.68,0,0,0,.13-.56,4.84,4.84,0,0,0,0-.85,2.14,2.14,0,0,0-.15-.76,2.17,2.17,0,0,0-.53-.77,1.57,1.57,0,0,0-.68-.37h0l-.18,0h-.17a1.93,1.93,0,0,0-.48,0A2,2,0,0,0,38.2,9Z" transform="translate(-6.86 -5.86)"/>
  </g>
  
  <g id="g-oor-r" className="giraf__ear"><path className="g-yellow-2" d="M97.08,18.64a.7.7,0,0,0-.51-.31H96.5a57.17,57.17,0,0,0-7,1.27,32,32,0,0,0-7.19,2.68,16.26,16.26,0,0,0-5,3.87,11.52,11.52,0,0,0-1.64,2.59,9.74,9.74,0,0,0-.56,1.52l-2.39,5.32,6.63-.38c.32,0,.65-.05,1-.1a23.33,23.33,0,0,0,4.1-.94,20.77,20.77,0,0,0,3.72-1.66,19.05,19.05,0,0,0,3.26-2.36,17.49,17.49,0,0,0,3.71-4.74,18.76,18.76,0,0,0,2-6.25A.67.67,0,0,0,97.08,18.64Z" transform="translate(-6.86 -5.86)"/>
    <path className="cheek" d="M90.44,24a41.53,41.53,0,0,0-8.37,2.39h0A12.71,12.71,0,0,0,79.5,28a9.55,9.55,0,0,0-1.37,1.47l-.36.55-.21.35c-.18.33-.34.68-.49,1A19.41,19.41,0,0,1,79,33.3c.81-.38,1.63-.77,2.43-1.17a29.94,29.94,0,0,0,5.9-3.62,15,15,0,0,0,2.57-2.77,13.62,13.62,0,0,0,1.07-1.9l-.5.12Z" transform="translate(-6.86 -5.86)"/>
  </g>

  <path className="g-white-2" d="M89.9,25.7l0-.07,0,.07Z" transform="translate(-6.86 -5.86)"/>
  <path className="g-white-2" d="M82.05,26.35h0l.17-.09-.19.09Z" transform="translate(-6.86 -5.86)"/>
  
  <g id="oor-l">
    <path className="g-yellow-2" d="M29.87,31.2a20.85,20.85,0,0,0-4.78-5.1A27.61,27.61,0,0,0,17,22a37.45,37.45,0,0,0-4.62-1.19c-1.78-.34-3.56-.55-4.91-.69a.62.62,0,0,0-.45.21.66.66,0,0,0-.16.46,14.93,14.93,0,0,0,2.7,7.49,14.91,14.91,0,0,0,5.49,4.48,25.15,25.15,0,0,0,7,2.13c1.36.23,2.81.4,4.3.5l7.15,1Z" transform="translate(-6.86 -5.86)"/>
    <path className="cheek" d="M15.23,26.58a14.44,14.44,0,0,0,2.21,2.28,19.27,19.27,0,0,0,3.67,2.26,23.18,23.18,0,0,0,5.23,1.7A15.33,15.33,0,0,0,28,33c.06-.08.11-.16.17-.23-.33-.39-.67-.78-1-1.15A33.13,33.13,0,0,0,24.21,29h0a28,28,0,0,0-5.4-3.58,14.75,14.75,0,0,0-3.29-1c-.6-.1-1.2-.17-1.79-.24a21.41,21.41,0,0,0,1.49,2.41Z" transform="translate(-6.86 -5.86)"/>
  </g>
  <path className="g-yellow-2" d="M83.07,42.83a31.72,31.72,0,0,0-3.7-7.63l-4.24-4.94a22,22,0,0,0-1.72-1.48,27.11,27.11,0,0,0-3.8-2.46,29.17,29.17,0,0,0-4.36-1.87,37.22,37.22,0,0,0-4.79-1.25,43.23,43.23,0,0,0-4.88-.65l-.57,0a37.54,37.54,0,0,0-8.72.48,33.32,33.32,0,0,0-8.41,2.67,29.54,29.54,0,0,0-7.12,4.71l-.89.83-3.48,4.17c-.37.53-.72,1.08-1,1.62a28.05,28.05,0,0,0-1.65,3.29c-.15.3-.3.6-.44.9A34.55,34.55,0,0,0,21,48a45,45,0,0,0-.74,14.7,35.6,35.6,0,0,0,1.45,6.72,20.72,20.72,0,0,0,2.64,5.42,16.94,16.94,0,0,0,1.32,1.65,18.55,18.55,0,0,0,1.78,1.76,19.89,19.89,0,0,0,4.08,2.69,27.06,27.06,0,0,0,9.34,2.54c.62.07,1.3.13,2,.17a62.56,62.56,0,0,0,7.94-.06c1.66-.09,3.28-.23,4.78-.36,1.7-.15,3.12-.28,4.59-.47a48.35,48.35,0,0,0,9.19-2,24.76,24.76,0,0,0,8-4.18,17.69,17.69,0,0,0,3.1-3.21,17.34,17.34,0,0,0,2.24-4.1A31.91,31.91,0,0,0,84,64.82a42.91,42.91,0,0,0-.89-22Z" transform="translate(-6.86 -5.86)"/>
  <path className="g-white-2" d="M24.38,29.14l-.11-.1a.52.52,0,0,0,.11.1Z" transform="translate(-6.86 -5.86)"/><path className="g-white" d="M42.23,31.91a.42.42,0,0,0-.23.24.43.43,0,0,0,0,.35c.17.58.33,1.17.5,1.75a.38.38,0,0,0,.14.19.52.52,0,0,0,.34.1.46.46,0,0,0,.29-.17.39.39,0,0,0,.09-.33c-.16-.63-.35-1.25-.53-1.87a.45.45,0,0,0-.24-.25.47.47,0,0,0-.16,0,.64.64,0,0,0-.2,0Z" transform="translate(-6.86 -5.86)"/><path className="g-white" d="M50.12,30.62a.4.4,0,0,0-.3.17.46.46,0,0,0-.09.33,11.67,11.67,0,0,0,.48,2.6.45.45,0,0,0,.25.24.43.43,0,0,0,.35,0,.49.49,0,0,0,.24-.23.5.5,0,0,0,0-.36c0-.09,0-.17-.07-.26a10.7,10.7,0,0,1-.34-2.1.47.47,0,0,0-.44-.4Z" transform="translate(-6.86 -5.86)"/><path className="g-white" d="M56.57,30.54a8.55,8.55,0,0,0,.08,1.89.46.46,0,0,0,.5.39.43.43,0,0,0,.38-.49,8,8,0,0,1-.09-1.09,5.06,5.06,0,0,1,0-.55.49.49,0,0,0-.39-.52h0a.44.44,0,0,0-.46.37Zm.86.62s0,0,0,0v0Z" transform="translate(-6.86 -5.86)"/><path className="g-white" d="M62.6,30l-.21,1.66a.48.48,0,0,0,.39.52.44.44,0,0,0,.51-.38l.21-1.66a.47.47,0,0,0-.39-.52h0a.46.46,0,0,0-.47.39Z" transform="translate(-6.86 -5.86)"/>
  <path className="g-white" d="M46.91,37.26a.44.44,0,0,0-.39.5A14.67,14.67,0,0,0,47,39.61a.48.48,0,0,0,.61.26.43.43,0,0,0,.24-.24.5.5,0,0,0,0-.36l-.12-.4q-.16-.6-.27-1.2a.49.49,0,0,0-.44-.42Z" transform="translate(-6.86 -5.86)"/>
  <path className="g-white" d="M53.71,37.82a.44.44,0,0,0-.39.5,9.89,9.89,0,0,0,.36,1.7.44.44,0,0,0,.25.25.43.43,0,0,0,.35,0,.41.41,0,0,0,.24-.24.43.43,0,0,0,0-.35s0-.09,0-.13a8.89,8.89,0,0,1-.25-1.33.48.48,0,0,0-.45-.41Zm.75,1.64,0,.07a.14.14,0,0,1,0-.07Z" transform="translate(-6.86 -5.86)"/>
  <path className="g-white" d="M59.76,37.24c-.1.62-.2,1.24-.28,1.86a.49.49,0,0,0,.4.53.44.44,0,0,0,.51-.38c.08-.62.17-1.24.28-1.86a.48.48,0,0,0-.4-.52h-.05a.45.45,0,0,0-.46.38Z" transform="translate(-6.86 -5.86)"/><path className="g-white" d="M50.6,43.57a.45.45,0,0,0-.39.51,4.86,4.86,0,0,0,.48,1.74.47.47,0,0,0,.6.25.44.44,0,0,0,.24-.59,6,6,0,0,1-.28-.7,4.45,4.45,0,0,1-.13-.8.47.47,0,0,0-.45-.41Z" transform="translate(-6.86 -5.86)"/>
  <path className="g-white" d="M57.52,42.64a.39.39,0,0,0-.16.28,17,17,0,0,0,0,1.75.47.47,0,0,0,.5.4.43.43,0,0,0,.37-.49c0-.39,0-.78,0-1.17v-.35a.45.45,0,0,0-.09-.33.46.46,0,0,0-.28-.17H57.8a.47.47,0,0,0-.28.09Z" transform="translate(-6.86 -5.86)"/>
  <path className="g-white" d="M55.13,47.9a.44.44,0,0,0-.31.17.44.44,0,0,0-.09.35,9.45,9.45,0,0,1,.25,1.2.49.49,0,0,0,.53.41.45.45,0,0,0,.4-.51c-.06-.4-.15-.8-.25-1.2a.52.52,0,0,0-.18-.32.45.45,0,0,0-.27-.1Z" transform="translate(-6.86 -5.86)"/>
  <path className="g-white" d="M56.71,53.8c-4.63-.28-11.59,1.92-14.15,5.62a9.42,9.42,0,0,0-1.44,3.44A12.21,12.21,0,0,0,40.87,65,13.51,13.51,0,0,0,41,67a9.16,9.16,0,0,0,.95,3.12,8.15,8.15,0,0,0,1.86,2.34A9.38,9.38,0,0,0,46.27,74a13.56,13.56,0,0,0,3,.88,17.17,17.17,0,0,0,2,.23c.42,0,.83,0,1.25,0A23.37,23.37,0,0,0,55.78,75a28.48,28.48,0,0,0,3.2-.66,22.78,22.78,0,0,0,2.82-1,13.42,13.42,0,0,0,1.42-.7,11.91,11.91,0,0,0,1.7-1.21,10.66,10.66,0,0,0,2.5-3.25,9.4,9.4,0,0,0,1-3.86,9.19,9.19,0,0,0-.75-4.06,10,10,0,0,0-2.86-3.74,14.13,14.13,0,0,0-8.1-2.7Z" transform="translate(-6.86 -5.86)"/>
  <path className="g-lines" d="M31.46,50.22a4,4,0,0,0-1.26.44,4.32,4.32,0,0,0-2.29,3.71.52.52,0,0,0,.09.34.48.48,0,0,0,.29.17.43.43,0,0,0,.5-.36,4.43,4.43,0,0,1,.12-.89,4,4,0,0,1,.33-.8,4.06,4.06,0,0,1,.57-.74,4.38,4.38,0,0,1,.7-.52,3.61,3.61,0,0,1,.89-.36,3.94,3.94,0,0,1,.77-.08,3.1,3.1,0,0,1,.68.1,3.87,3.87,0,0,1,.55.25,2.73,2.73,0,0,1,.47.37,3.55,3.55,0,0,1,.43.57,4.08,4.08,0,0,1,.37.89c0,.11.06.22.08.33a.51.51,0,0,0,.19.32.47.47,0,0,0,.76-.42,4.25,4.25,0,0,0-1.62-2.76,3.33,3.33,0,0,0-1.77-.6,3.46,3.46,0,0,0-.85,0Zm-2.17,2.53,0,0,0,0Zm-.39.92,0,0s0,0,0,0Z" transform="translate(-6.86 -5.86)"/>
  <path className="g-lines" d="M75.41,49.08a4,4,0,0,0-1.13.3A4.37,4.37,0,0,0,73.2,50a4.2,4.2,0,0,0-.82.92,4,4,0,0,0-.54,1.27.41.41,0,0,0,.09.34.45.45,0,0,0,.29.18.45.45,0,0,0,.34-.08.48.48,0,0,0,.17-.29v0A3.11,3.11,0,0,1,73.53,51a4.58,4.58,0,0,1,.75-.56l0,0,.05,0,0,0,0,0a3.58,3.58,0,0,1,.82-.32,3.25,3.25,0,0,1,.77-.09,3,3,0,0,1,.51.08,2.08,2.08,0,0,1,.43.19,2.26,2.26,0,0,1,.29.23,2.63,2.63,0,0,1,.29.37,2.44,2.44,0,0,1,.21.53,3.6,3.6,0,0,1,.12.79.49.49,0,0,0,.53.42.42.42,0,0,0,.31-.17.46.46,0,0,0,.09-.34,4,4,0,0,0-.3-1.4,2.82,2.82,0,0,0-.76-1A2.71,2.71,0,0,0,76,49.07a3.62,3.62,0,0,0-.57,0Zm-2.35,2.5,0,.05,0-.05Zm0,.07,0,0,0,0Zm-.27.64v0Zm1.54-1.88h0Z" transform="translate(-6.86 -5.86)"/>
  <path className="g-lines" d="M78.74,47.85a.41.41,0,0,0-.24.24.75.75,0,0,0-.07.12,5.16,5.16,0,0,1-.67.87q-.32.3-.66.57a.45.45,0,0,0-.08.63.46.46,0,0,0,.64.09,7.1,7.1,0,0,0,.92-.82,4.8,4.8,0,0,0,.75-1.09.46.46,0,0,0-.24-.6.47.47,0,0,0-.16,0,.4.4,0,0,0-.19,0Zm-.95,1.2,0,0,0,0Z" transform="translate(-6.86 -5.86)"/>
  <path className="g-lines" d="M79.85,49.68a7.76,7.76,0,0,1-.95.67l-.25.13a.49.49,0,0,0-.25.24.52.52,0,0,0,0,.37.49.49,0,0,0,.62.27,7,7,0,0,0,1.41-.92.46.46,0,0,0,.08-.66.5.5,0,0,0-.36-.19.4.4,0,0,0-.3.09Z" transform="translate(-6.86 -5.86)"/>
  <path className="g-lines" d="M28.11,49.65a.43.43,0,0,0-.23.57,6.77,6.77,0,0,0,1.1,2,.46.46,0,0,0,.63.09.44.44,0,0,0,.08-.62,8.17,8.17,0,0,1-.5-.7,6.84,6.84,0,0,1-.5-1.06.45.45,0,0,0-.39-.27.31.31,0,0,0-.19,0Zm1,1.21,0,0,0,0Z" transform="translate(-6.86 -5.86)"/>
  <path className="g-lines" d="M26.52,51.37a.43.43,0,0,0-.3.16.46.46,0,0,0-.09.34.5.5,0,0,0,.18.31l.07.07a2.11,2.11,0,0,0,.23.19,5.76,5.76,0,0,0,.51.35,3.88,3.88,0,0,0,1.13.43.45.45,0,0,0,.51-.38.48.48,0,0,0-.39-.53l-.21-.05a4.69,4.69,0,0,1-.72-.33,3.73,3.73,0,0,1-.58-.47.41.41,0,0,0-.26-.09Zm1.56.86.07,0-.07,0Z" transform="translate(-6.86 -5.86)"/>
  <path className="g-lines" d="M47.39,58.45h-.16a1.39,1.39,0,0,0-.46.13,5.89,5.89,0,0,0-.94.48,6.2,6.2,0,0,0-1.42,1.23A3.67,3.67,0,0,0,44,61c-.05.12-.08.24-.12.36a2.24,2.24,0,0,0,0,.5,1.51,1.51,0,0,0,.29.81,1.26,1.26,0,0,0,.82.52.8.8,0,0,0,.15,0h.28a2.57,2.57,0,0,0,.4-.08,4,4,0,0,0,.95-.45,4.94,4.94,0,0,0,1.44-1.33,2.69,2.69,0,0,0,.56-1.76,1.29,1.29,0,0,0-.84-1.09,1.36,1.36,0,0,0-.35-.07Z" transform="translate(-6.86 -5.86)"/>
  <path className="g-lines" d="M60.64,57.42a1.92,1.92,0,0,0-.24,0,1.41,1.41,0,0,0-1.35,1,2.17,2.17,0,0,0,.61,1.9,5,5,0,0,0,1.59,1.21,4,4,0,0,0,1.47.4H63a2.2,2.2,0,0,0,.68-.13,1.74,1.74,0,0,0,.56-.32,1.35,1.35,0,0,0,.47-1.21,1.46,1.46,0,0,0-.19-.6A3,3,0,0,0,64,59l-.25-.22-.07-.07a8.17,8.17,0,0,0-1.44-.85,4.5,4.5,0,0,0-1.63-.46Z" transform="translate(-6.86 -5.86)"/>
  <path className="g-lines" d="M57.8,68.24a1.84,1.84,0,0,1-.17.38,2.18,2.18,0,0,1-.33.42,3.82,3.82,0,0,1-.64.48,5.37,5.37,0,0,1-1.25.47,7,7,0,0,1-1.65.16,5.66,5.66,0,0,1-1.52-.23,4.79,4.79,0,0,1-.81-.34,3.15,3.15,0,0,1-.29-.22.46.46,0,0,0-.63.07.47.47,0,0,0,.09.64,4.44,4.44,0,0,0,2,.86A7.08,7.08,0,0,0,55,71c1.46-.19,3.12-.88,3.62-2.38a.48.48,0,0,0-.25-.6l-.15,0a.45.45,0,0,0-.44.27Z" transform="translate(-6.86 -5.86)"/>
  <path className="g-cheek" d="M26.41,62.36A4.6,4.6,0,0,0,26,64.45a4.17,4.17,0,0,0,1.55,3.11,5.44,5.44,0,0,0,3.34,1.22H31a5.14,5.14,0,0,0,1.65-.27,5.6,5.6,0,0,0,1.76-1,4.59,4.59,0,0,0,1.18-1.43A3.84,3.84,0,0,0,36,64.41a3.66,3.66,0,0,0-.28-1.5,4.92,4.92,0,0,0-.89-1.41,5.09,5.09,0,0,0-8.42.86Z" transform="translate(-6.86 -5.86)"/>
  <path className="g-cheek" d="M72.27,60.29a4.59,4.59,0,0,0-.44,2.09,4.13,4.13,0,0,0,1.54,3.11,5.49,5.49,0,0,0,3.35,1.22h.13a5.19,5.19,0,0,0,1.65-.27,5.82,5.82,0,0,0,1.76-1A4.73,4.73,0,0,0,81.44,64a3.8,3.8,0,0,0,.42-1.64,3.5,3.5,0,0,0-.28-1.5,4.68,4.68,0,0,0-.88-1.41,5.1,5.1,0,0,0-8.43.86Z" transform="translate(-6.86 -5.86)"/>
    </g>
    </g>
    </svg>
  </div>
  )
}

export default function Bear() {

  useEffect(() => {
  
    // select elements
    const eyes = document.querySelector(".eyes");
    const head = document.querySelector(".head");
    const ears = document.querySelector(".ears");
    const nose = document.querySelector(".nose");
    const snout = document.querySelector(".snout");

    // init cursor position
    let cursorPos = { x: 0, y: 0 };

    // get window size
    let windowWidth = window.innerWidth;
    let windowHeight = window.innerHeight;

    function setWindowSize() {
        windowWidth = window.innerWidth;
        windowHeight = window.innerHeight;
    };

    function mousemove(e) {
      cursorPos = { x: e.clientX, y: e.clientY } 
      initFolow();
    }

    function touchmove(e) {
      cursorPos = { x: e.targetTouches[0].offsetX, y: e.targetTouches[0].offsetY}
      initFolow();
    }

    const followCursor = (el, xRatio, yRatio) => {
      const elOffset = el.getBoundingClientRect();
      const centerX = elOffset.x + elOffset.width / 2;
      const centerY = elOffset.y + elOffset.height / 2;
      const distanceLeft = Math.round(((cursorPos.x - centerX) * 100) / window.innerWidth);
      const distanceTop = Math.round(((cursorPos.y - centerY) * 100) / window.innerHeight);  
      el.style.transform = `translate(${distanceLeft / xRatio}px, ${distanceTop / yRatio}px)`;
    }

    const initFolow = () => {
      if (ears) followCursor(ears, -2.8, -2.8)
      if (head) followCursor(head, 6, 6)
      if (eyes) followCursor(eyes, 1.8, 1.8)
      if (snout) followCursor(snout, 1.5, 1.7)
      if (nose) followCursor(nose, 1, 1)
    }

    window.addEventListener('resize', setWindowSize);
    window.addEventListener("mousemove", mousemove);
    window.addEventListener("touchmove", touchmove);

    return () => {
      window.removeEventListener('resize', setWindowSize);
      window.removeEventListener('mousemove', mousemove);
      window.removeEventListener('touchmove', touchmove);
    }

  }, []);
  
  return (
    <>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -20 446.4 686.5" className="bear">
      <g id="bear">
        <ellipse cx="223.2" cy="637.6" rx="223.2" ry="29" fill="#3F1C0C" opacity="0.3"/>
        <g id="body">
          <path d="M376.3,460.3c0,63.2-17.1,120.3-44.7,161.4h0l-4.5,6.5a18.9,18.9,0,0,1-13,5.1H260.5a19.1,19.1,0,0,1-19-19V553.2a124.2,124.2,0,0,1-30.5.3v62a19.1,19.1,0,0,1-19,19H138.5a19,19,0,0,1-15.2-7.6l-0.5-.7C93.4,584.6,75.1,525.7,75.1,460.3c0-125.3,67.4-226.9,150.6-226.9S376.3,335,376.3,460.3Z" fill="#e07320"/>
          <path d="M211,596.9v18.4a19.1,19.1,0,0,1-19,19H138.5a19,19,0,0,1-15.2-7.7l-0.5-.7C93.4,584.6,75.1,525.7,75.1,460.3q0-4.6.1-9.2c1.6,61.6,19.6,117,47.5,156.4l0.5,0.8a19,19,0,0,0,15.2,7.7H192A19.1,19.1,0,0,0,211,596.9Z" fill="#af571d"/>
          <path d="M376.3,459.3c0,63.1-17.1,120.3-44.7,161.4h0l-4.5,6.5a18.9,18.9,0,0,1-13,5.2H260.5a19.1,19.1,0,0,1-19-19V594.9a19.1,19.1,0,0,0,19,19H314a18.9,18.9,0,0,0,13-5.2l4.5-6.5h0c26.3-39.1,43-92.7,44.6-152.2Q376.3,454.7,376.3,459.3Z" fill="#af571d"/>
          <path id="body-stroke" d="M376.3,460.3c0,63.2-17.1,120.3-44.7,161.4h0l-4.5,6.5a18.9,18.9,0,0,1-13,5.1H260.5a19.1,19.1,0,0,1-19-19V553.2a124.2,124.2,0,0,1-30.5.3v62a19.1,19.1,0,0,1-19,19H138.5a19,19,0,0,1-15.2-7.6l-0.5-.7C93.4,584.6,75.1,525.7,75.1,460.3c0-125.3,67.4-226.9,150.6-226.9S376.3,335,376.3,460.3Z" fill="none" stroke="#3f1c0d" strokeMiterlimit="10" strokeWidth="10"/>
          <path id="arm-right" d="M294.1,283h0a41.6,41.6,0,0,1,56.7,15.2l67.1,116.3a41.6,41.6,0,0,1-15.2,56.7h0A41.6,41.6,0,0,1,346.1,456L322,414.3Z" fill="#e07320"/>
          <path id="arm-right-shadow" d="M325,410.8s15.1,34.1,20.8,45.2c7,13.6,21.7,20.7,36,20.7a41.1,41.1,0,0,0,20.6-5.5A41.6,41.6,0,0,0,422,425.6a41.3,41.3,0,0,1-40.2,32c-14.4,0-25.5-10.5-35-22.6C332.9,417.4,325,410.8,325,410.8Z" fill="#af571d"/>
          <path id="arm-left" d="M152.4,283h0a41.6,41.6,0,0,0-56.7,15.2L28.6,414.5a41.6,41.6,0,0,0,15.2,56.7h0A41.6,41.6,0,0,0,100.5,456l25.5-42.1Z" fill="#e07320"/>
          <path id="arm-left-shadow" d="M121.2,410.8s-15.1,34.1-20.8,45.2c-7,13.6-21.7,20.7-36,20.7a41.1,41.1,0,0,1-20.6-5.5,41.6,41.6,0,0,1-19.6-45.5,41.3,41.3,0,0,0,40.2,32c14.4,0,25.5-10.5,35-22.6C113.3,417.4,121.2,410.8,121.2,410.8Z" fill="#af571d"/>
          <path id="neck-shadow" d="M351.9,300.1c-33.4,19.3-78.7,27.6-128.6,27.6s-95.2-8.3-128.6-27.6l1.1-1.9a41.6,41.6,0,0,1,40.4-20.5c25-27.9,56-44.4,89.5-44.4s64.3,16.4,89.3,44.1a41.6,41.6,0,0,1,35.9,20.7Z" fill="#af571d"/>
          <path id="arm-right-stroke" d="M294.1,283h0a41.6,41.6,0,0,1,56.7,15.2l67.1,116.3a41.6,41.6,0,0,1-15.2,56.7h0A41.6,41.6,0,0,1,346.1,456L322,414.3" fill="none" stroke="#3f1c0d" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="10"/>
          <path id="arm-left-stroke" d="M152.4,283h0a41.6,41.6,0,0,0-56.7,15.2L28.6,414.5a41.6,41.6,0,0,0,15.2,56.7h0A41.6,41.6,0,0,0,100.5,456l24.1-41.7" fill="none" stroke="#3f1c0d" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="10"/>
          <path id="belly" d="M304.8,435.8c0,46.9-36.5,85-81.5,85s-81.5-38-81.5-85S179.5,332,224.5,332,304.8,388.9,304.8,435.8Z" fill="#ee9e5c"/>
          <path d="M299.4,504.7a33,33,0,0,1-.4,4.9c-3.7,24.9-35.1,44.4-73.4,44.4s-69.8-19.5-73.4-44.5a32.7,32.7,0,0,1-.3-4.8h0a2.4,2.4,0,0,1,4.5-1.2c10.3,18.9,37.4,32.4,69.3,32.4s58.9-13.5,69.3-32.4a2.4,2.4,0,0,1,4.5,1.2h0Z" fill="#af571d"/>
          <path d="M165.7,533.3c39.9,27.3,80.5,27.7,120,0" fill="none" stroke="#3f1c0d" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="10"/>
        </g>
        <g id="head" className="head">
          <g id="ears" className="ears">
            <g>
              <circle cx="92.2" cy="53.9" r="48.9" fill="#e07320" stroke="#3f1c0d" strokeMiterlimit="10" strokeWidth="10"/>
              <circle cx="93.1" cy="53.9" r="31.9" fill="#ee9e5c"/>
            </g>
            <g>
              <circle cx="351.6" cy="53.9" r="48.9" fill="#e07320" stroke="#3f1c0d" strokeMiterlimit="10" strokeWidth="10"/>
              <circle cx="352.5" cy="53.9" r="31.9" fill="#ee9e5c"/>
            </g>
          </g>
          <path d="M409.6,176.9c0,94.7-83.5,130.1-186.4,130.1S36.8,271.6,36.8,176.9,120.3,5.4,223.2,5.4,409.6,82.2,409.6,176.9Z" fill="#e07320"/>
          <path id="head-stroke" d="M409.6,176.9c0,94.7-83.5,130.1-186.4,130.1S36.8,271.6,36.8,176.9,120.3,5.4,223.2,5.4,409.6,82.2,409.6,176.9Z" fill="none" stroke="#3f1c0d" strokeMiterlimit="10" strokeWidth="10"/>
          <g id="eyes" className="eyes">
            <circle cx="291.1" cy="138" r="16.8" fill="#3f1c0c"/>
            <circle cx="287.3" cy="134.2" r="4.8" fill="#884d3a"/>
            <circle cx="155.3" cy="138" r="16.8" fill="#3f1c0c"/>
            <circle cx="152.3" cy="134.2" r="4.8" fill="#884d3a"/>
            <path d="M117.1,106.7l0.5-2.2,0.3-1.1,0.6-1.5a23.6,23.6,0,0,1,1.6-3.5l1.1-1.9,1.3-1.9,0.7-1,0.8-.9,1.7-1.9a33.4,33.4,0,0,1,14.1-8.3,36.2,36.2,0,0,1,5.3-1.1l2.6-.2h4.8l2.1,0.4,1.9,0.4,1.7,0.5,1.5,0.5,1.2,0.5,2.2,1.1a3.2,3.2,0,0,1-1.7,6h-0.4l-2-.2h-6.9l-1.7.2h-0.9l-0.9.2-1.9.3c-1.3.4-2.6,0.6-3.8,1.1a40.3,40.3,0,0,0-3.8,1.5,39.5,39.5,0,0,0-6.9,4.3l-1.4,1.3-0.7.6-0.6.7-1.2,1.3-1,1.3-1,1.2-0.8,1.1-0.7.9-0.6,1-1.2,1.9A3.2,3.2,0,0,1,117.1,106.7Z" fill="#3f1c0d"/>
            <path d="M323.4,109.2l-1.2-1.9-0.6-1-0.7-.9-0.8-1.1-1-1.2-1-1.3-1.2-1.3-0.6-.7-0.7-.6-1.4-1.3a31.6,31.6,0,0,0-10.7-5.8c-1.3-.5-2.6-0.7-3.8-1.1l-1.9-.3-0.9-.2h-0.9l-1.7-.2h-6.9l-2,.2h-0.4a3.2,3.2,0,0,1-1.7-6l2.2-1.1,1.2-.5,1.5-.5,1.7-.5,1.9-.4,2.1-.4h4.8l2.6,0.2a36.2,36.2,0,0,1,5.3,1.1,35.1,35.1,0,0,1,14.1,8.3l1.7,1.9,0.8,0.9,0.7,1,1.3,1.9,1.1,1.9a23.6,23.6,0,0,1,1.6,3.5l0.6,1.5,0.3,1.1,0.5,2.2A3.2,3.2,0,0,1,323.4,109.2Z" fill="#3f1c0d"/>
          </g>
          <g >
            <path className="snout" d="M308.5,230c0,43.6-40.2,52.6-87.3,52.6s-83.3-9-83.3-52.6,38.2-79,85.3-79S308.5,186.3,308.5,230Z" fill="#ee9e5c"/>
            <g className="nose">
              <path d="M197.6,187.5c0-11.1,11.5-11.7,25.6-11.7s25.6,0.7,25.6,11.7-11.5,20-25.6,20S197.6,198.6,197.6,187.5Z" fill="#3f1c0c"/>
              <path d="M227.5,201a13.9,13.9,0,0,1,.2,1.7v4.6a109.6,109.6,0,0,1-.4,14.3c-0.1,1.3-.3,2.6-0.5,3.9s-0.4,2.6-.7,3.8a34.2,34.2,0,0,1-.9,3.5,32.6,32.6,0,0,1-1.2,3,23.6,23.6,0,0,1-1.3,2.4l-1.2,1.8-1.2,1.4a2,2,0,0,1-3.5-1.6v-0.2s0.1-.6.3-1.7,0.2-1.1.3-1.8,0.3-1.5.4-2.4,0.1-.9.2-1.3,0.2-.9.2-1.4,0.3-2,.4-3.1,0.3-2.2.3-3.3,0.1-2.3.1-3.5a101.1,101.1,0,0,0-1-13.1q-0.4-2.7-.6-4.3a13.9,13.9,0,0,1-.2-1.7A5,5,0,0,1,227.5,201Z" fill="#3f1c0d"/>
              <path d="M233.7,244.2l-1.4.2-3.8.3H223l-3.1-.3-3.2-.4a46.8,46.8,0,0,1-6.2-1.4,53.2,53.2,0,0,1-5.3-1.9l-2-1-1.5-1-1.2-1a2.1,2.1,0,0,1,1.3-3.6h3.2l2,0.2,2.4,0.4,2.6,0.4L218,236l5.8,0.9,5.1,1,3.6,0.9,1.4,0.4A2.6,2.6,0,0,1,233.7,244.2Z" fill="#3f1c0d"/>
              <ellipse cx="223.4" cy="183.3" rx="11.7" ry="3.5" fill="#884d3a"/>
            </g>
          </g>
        </g>
      </g>
    </svg>
    {/* <Giraff /> */}
    </>
  );
}